/**
 * Enable the routing of a user on the API.
 * 
 * @module routing
*/
import params from "./params/params.json";
import UtilitiesBase from "./webComponents/build/utilitiesBase.js";

/**
 * Uri to folder: lib.
 * 
 * @type {string}
 */
export const sourceUri = params["rootUri"]+"lib/";

/**
 * Uri to folder: parent of src.
 * 
 * @type {string}
 */
export const rootUri = params["rootUri"];

/**
 * The object of the current page.
 * 
 * @private
 * @type {?WebBase}
 */
let domain = null;

/**
 * Build API pages.
 * 
 * @private
 */
class Routing {
	/**
	 * Parse path.
	 * 
	 * @param {string} uri - The uri to parse.
	 * 
	 * @return {Array<string>}
	 */
	static parseRoute(uri) {
		return uri.split("/");
	}
	
	/**
	 * Change the page displayed by the API
	 * 
	 * @param {string} path - The path to the page source.
	 * @param {Map<string,*>} init - Initialization configuration of the page.
	 */
	static async setPage(path,init) {
		const res = await (await import("./pages/"+path+".js")).default(init);
		if(window.document.querySelector("#body") === null) {
			const div = window.document.createElement("div");
			div.id = "body";
			window.document.body.appendChild(div);
		}
		window.document.querySelector("#body").innerHTML = res;
		domain = window.document.querySelector("#main");
		window.dispatchEvent(new Event("load"));
	}
}

/**
 * User session.
 * 
 * @type {Map<string,*>}
 */
export const session = new Map();

/**
 * @typedef HistoryState
 * @type {object}
 * @property {Array<string>} history - List of urls of the history.
 * @property {number} position - Current integer (-1 for initialization or non negative) position in the history.
 */
/**
 * User history state.
 * 
 * @type {HistoryState}
 */
export let state = {
	"history": [],
	"position": -1
};

/**
 * Get a redirect which is not an error.
 * 
 * @param {number} positionShift
 * @param {string} [defaultRedirect=""]
 */
export function getRedirectNotError(positionShift,defaultRedirect = "") {
	const sign = positionShift >= 0;
	const increment = sign ? 1 : -1;
	let i = 0;
	let position = state["position"]+i;
	while((sign && i < positionShift) || (!sign && -i < -positionShift)) {
		i += increment;
		position = state["position"]+i;
		if((!sign && position < 0) || (sign && position >= state["history"].length)) {
			return defaultRedirect;
		}
		if(Routing.parseRoute(state["history"][position])[0] === "error") {
			positionShift += increment;
		}
	}
	return state["history"][position];
}

/**
 * Manage the popstate event when the user use browser navigation bar
 * 
 * @private
 * @param {PopStateEvent} event
 */
async function popstate(event) {
	const startPosition = state["position"];
	const currentRoute = Routing.parseRoute(state["history"][startPosition]);
	state = event.state;
	const endPosition = state["position"];
	const searchRoute = Routing.parseRoute(state["history"][endPosition]);
	let valid = false;
	if(searchRoute[0] === "error") {
		if((endPosition-startPosition > 0 && endPosition < state["history"].length-1) || (endPosition-startPosition < 0 && endPosition > 0)) {
			valid = true;
			window.history.go(endPosition-startPosition > 0 ? 1 : -1);
		}
	} else if(!domain.getError()) {
		if(searchRoute.length === currentRoute.length) {
			if(searchRoute[0] === currentRoute[0]) {
				switch(currentRoute[0]) {
					case "entry":
					case "resource":
					case "news":
					case "media": {
						if(searchRoute[2] === currentRoute[2]) {
							valid = true;
							await domain.setParameters((new Map())
								.set("language",Number.parseInt(searchRoute[1],10))
							);
						}
						break;
					}
					case "search": {
						if(searchRoute[1] === currentRoute[1]) {
							valid = true;
							await domain.setParameters(searchRoute.length > 2 ? searchRoute[2] : null);
						}
						break;
					}
					case "backoffice": {
						if(searchRoute[1] === currentRoute[1] && searchRoute[2] === currentRoute[2] && searchRoute[1] === "select") {
							valid = true;
							await domain.setParameters(searchRoute.length > 3 ? searchRoute[3] : null);
						}
						break;
					}
					case "user": {
						if(searchRoute[1] === currentRoute[1] && searchRoute[1] === "monitoring") {
							valid = true;
							await domain.setParameters(searchRoute.length > 2 ? searchRoute[2] : null);
						}
						break;
					}
				}
			}
		}
	}
	if(!valid) {
		await routing(state["history"][state["position"]],0);
	}
}
window.addEventListener("popstate",async (event) => {await popstate(event);});

/**
 * Function to build a page of the API.
 * 
 * @param {string} uri - The uri of the page.
 * @param {number} [pushReplaceState=2] - 0 to not modify the history, 1 if we want to push the new page on the history (which also erase the forward history), 2 if we want the new page to replace the current one in history.
 */
export default async function routing(uri,pushReplaceState = 2) {
	UtilitiesBase.unsetNoindex();
	const url = window.location.origin+rootUri+uri;
	if(pushReplaceState === 1) {
		while(state["history"].length-1 > state["position"]) {
			state["history"].pop();
		}
		state["history"].push(uri);
		++state["position"];
		window.history.pushState(state,"",url);
	} else if(pushReplaceState === 2) {
		if(state["position"] === -1) {
			state["history"].push(uri);
			++state["position"];
		} else {
			state["history"][state["position"]] = uri;
		}
		window.history.replaceState(state,"",url);
	}
	const route = Routing.parseRoute(uri);
	const init = new Map();
	let valid = false;
	switch(route[0]) {
		case "": {
			if(route.length === 1) {
				valid = true;
				await Routing.setPage("frontoffice/home",init);
			}
			break;
		}
		case "newsletter": {
			if(route.length === 1) {
				valid = true;
				await Routing.setPage("frontoffice/newsletter", init);
			}
			break;
		}
		case "homeTest": {
			if(route.length === 1) {
				valid = true;
				await Routing.setPage("frontoffice/homeTest",init);
			}
			break;
		}
		case "entry":
		case "resource":
		case "news":
		case "media": {
			if(route.length === 3) {
				valid = true;
				init.set("type",route[0]);
				init.set("language",Number.parseInt(route[1],10));
				init.set("id",Number.parseInt(route[2],10));
				await Routing.setPage("frontoffice/item",init);
			}
			break;
		}
		case "search": {
			if(route.length >= 2 && route.length <= 3) {
				if(["all","entry","resource","news","media"].includes(route[1])) {
					valid = true;
					init.set("type",route[1] !== "all" ? route[1] : "");
					init.set("get",route.length > 2 ? route[2] : null);
					await Routing.setPage("frontoffice/"+route[0],init);
				}
			}
			break;
		}
		case "backoffice": {
			if(route.length >= 3) {
				if(["insert","api","update","select"].includes(route[1])) {
					switch(route[1]) {
						case "insert": {
							if(route.length === 3) {
								if(["language","keyword","theme","entry_category","entry","resource_type","resource_level","resource_scholar_level","resource","subscription_type","ip_user","email_user","youtube_user","twitter_user"].includes(route[2])) {
									valid = true;
									init.set("type",route[2]);
								}
							}
							break;
						}
						case "update": {
							if(route.length === 4) {
								if(["language","keyword","theme","entry_category","entry","resource_type","resource_level","resource_scholar_level","resource","subscription_type","ip_user","email_user","youtube_user","twitter_user"].includes(route[2])) {
									valid = true;
									init.set("type",route[2]);
									init.set("id",Number.parseInt(route[3],10));
								}
							}
							break;
						}
						case "select": {
							if(route.length <= 4) {
								if(["language","keyword","theme","entry_category","entry","resource_type","resource_level","resource_scholar_level","resource","news","media","resource_suggestion_user","resource_suggestion_api","subscription_type","ip_user","email_user","youtube_user","twitter_user"].includes(route[2])) {
									valid = true;
									init.set("type",route[2]);
									init.set("get",route.length > 3 ? route[3] : null);
								}
							}
							break;
						}
						case "api": {
							if(route.length === 3) {
								if(["youtube","twitter"].includes(route[2])) {
									valid = true;
									init.set("type",route[2]);
								}
							}
							break;
						}
					}
					if(valid) {
						await Routing.setPage("backoffice/"+route[1],init);
					}
				}
			} else {
				valid = true;
				await Routing.setPage("backoffice/home",init);
			}
			break;
		}
		case "user": {
			if(route.length >= 2) {
				if(["subscribe","valid_subscribe","email_verified","login","lost_password","logout","valid_lost_password","panel","subscription","monitoring","manage","modify","suggestion"].includes(route[1])) {
					if(route.length <= 3 && (route.length === 2 || ["email_verified","monitoring"].includes(route[1]))) {
						valid = true;
						init.set("type",route[1]);
						if(["email_verified","monitoring"].includes(route[1])) {
							init.set("get",route.length > 2 ? route[2] : null);
						}
						await Routing.setPage("frontoffice/"+route[0],init);
					}
				}
			}
			break;
		}
		case "partner": {
			if(route.length >= 2) {
				if(["virtual_human_body"].includes(route[1])) {
					switch(route[1]) {
						case "virtual_human_body": {
							if(route.length === 2) {
								valid = true;
								init.set("type",route[1]);
								await Routing.setPage("frontoffice/"+route[0],init);
							}
							break;
						}
					}
				}
			}
			break;
		}
		case "about":
		case "offer":
		case "legal":
		case "contact": {
			if(route.length === 1) {
				valid = true;
				await Routing.setPage("frontoffice/"+route[0],init);
			}
			break;
		}
	}
	if(!valid) {
		await Routing.setPage("frontoffice/error",init);
	}
}