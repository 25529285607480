/**
 * Enables build of layers.
 * 
 * @module webComponents/layer
 */
import Css from "css";
import {sourceUri} from "../../routing.js";

const baseUrl = window.location.origin+sourceUri+"client/webComponents/layer/";

/**
 * @abstract
 * @friend Layer
 */
export class LayerHolder extends HTMLElement {
	/**
	 * @private
	 */
	static id = 0;
	
	/**
	 * @private
	 */
	static properties = new Set(["begin-display","end-display","hide-not-child-layers","position","height","width","initialisation-display","css"]);
	
	/**
	 * @private
	 */
	constructor() {
		super();
		this.init(true);
	}
    
	/**
	 * @private
	 */
    connectedCallback() {
    	this.addEventListenerParent();
    	this.parents = this.getParents();
		this.buildResponsiveness(this.getDisplay());
    }
    
    /**
	 * @private
     */
    disconnectedCallback() {
    	this.removeEventListenerParent();
		this.buildResponsiveness(false);
    	this.parents.length = 0;
    }
	
    /**
	 * @private
     */
	static get observedAttributes() {
		return ["build"];
	}
	
	/**
	 * @private
	 */
	attributeChangedCallback(name,oldValue,newValue) {
		this.setBuild(newValue);
	}
	
	/**
	 * 
	 */
	buildProcedure(build) {
		Utilities.resetElement(this);
		this.init();
		for(const k of build.keys()) {
			if(!this.constructor.properties.has(k)) {
				throw new Error("");
			}
		}
		let initialisationDisplay = false;
		for(const [k,v] of build) {
			switch(k) {
				case "begin-display":
				case "end-display": {
					if(!(v instanceof Map)) {
						throw new Error("");
					}
					for(const k of v.keys()) {
						if(!["parent","layer"].includes(k)) {
							throw new Error("");
						}
					}
					for(const [kSub,vSub] of v) {
						if(!(vSub instanceof Array)) {
							throw new Error("");
						}
						for(const e of vSub) {
							if(typeof(e) !== "string") {
								throw new Error("");
							}
						}
						const set = new Set(vSub);
						if(set.size !== vSub.length) {
							throw new Error("");
						}
						this[Utilities.cssToCamelCase(k+"-"+kSub)] = set;
					}
					break;
				}
				case "hide-not-child-layers": {
					if(typeof(v) !== "boolean") {
						throw new Error("");
					}
					this[Utilities.cssToCamelCase(k)] = v;
					break;
				}
				case "position": {
					if(typeof(v) !== "string" || !["absolute","relative"].includes(v)) {
						throw new Error("");
					}
					this[Utilities.cssToCamelCase(k)] = v;
					break;
				}
				case "height":
				case "width": {
					if(typeof(v) !== "string" || !["absolute","relative","viewport"].includes(v)) {
						throw new Error("");
					}
					this[Utilities.cssToCamelCase(k)] = v;
					break;
				}
				case "initialisation-display": {
					if(typeof(v) !== "boolean") {
						throw new Error("");
					}
					initialisationDisplay = v;
					break;
				}
			}
		}
		if(this.isConnected) {
	    	this.addEventListenerParent();
		}
    	this.addEventListenerLayer();
		this.setDisplay(initialisationDisplay);
		const buildLayer = new Map();
		for(const e of Layer.properties) {
			if(build.has(e)) {
				buildLayer.set(e,build.get(e));
			}
		}
		this.layer.buildProcedure(buildLayer);
	}
	
	/**
	 * 
	 */
	setBuild(build) {
		this.buildProcedure(JSON.parse(build,Utilities.reviver));
	}
	
	/**
	 * 
	 */
	getLayer() {
		return this.layer.wrapper;
	}
	
	/**
	 * 
	 */
	getDisplay() {
		return this.display;
	}
	
	/**
	 * 
	 */
	setDisplay(display) {
		this.display = display;
		if(display) {
			const parentLayer = this.getParentLayer();
			if(parentLayer !== null) {
				parentLayer.setDisplay(display);
			}
			if(this.isConnected) {
				this.buildResponsiveness(true);
			}
		} else {
			for(const e of this.getChildLayers()) {
				e.setDisplay(display);
			}
			if(this.isConnected) {
				this.buildResponsiveness(false);
			}
		}
	}
	
	/**
	 * @private
	 */
	update() {
		if(this.isConnected) {
			const e = this.getBoundingClientRect();
			if(this.position === "relative") {
				this.layer.style.top = e.top.toString()+"px";
				this.layer.style.left = e.left.toString()+"px";
			} else {
				this.layer.style.top = "";
				this.layer.style.left = "";
			}
			switch(this.height) {
				case "absolute": {
					this.layer.style.height = "";
					break;
				}
				case "relative": {
					this.layer.style.height = e.height.toString()+"px";
					break;
				}
				case "viewport": {
					this.layer.style.height = window.innerHeight.toString()+"px";
					break;
				}
			}
			switch(this.width) {
				case "absolute": {
					this.layer.style.width = "";
					break;
				}
				case "relative": {
					this.layer.style.width = e.width.toString()+"px";
					break;
				}
				case "viewport": {
					this.layer.style.width = window.innerWidth.toString()+"px";
					break;
				}
			}
		}
	}
	
	/**
	 * @private
	 */
	buildResponsiveness(set) {
		for(const e of this.hideNotChildLayersElements) {
			e.style.display = "";
		}
		this.hideNotChildLayersElements.length = 0;
		if(set) {
			this.respondToVisibility.observe(this);
			window.addEventListener("scroll",this.updateScrollEvent);
			for(const e of this.parents) {
				e.addEventListener("scroll",this.updateScrollEvent);
			}
			window.addEventListener("resize",this.updateResizeEvent);
			this.style.display = "";
			if(this.hideNotChildLayers) {
				const childLayers = this.getDeepChildLayers();
				for(const e of window.document.body.childNodes) {
					if(e.nodeType === Node.ELEMENT_NODE && !childLayers.includes(e)) {
						this.hideNotChildLayersElements.push(e);
						e.style.display = "none";
					}
				}
			}
			this.update();
		   	window.document.body.appendChild(this.layer);
		} else {
			this.respondToVisibility.disconnect();
			window.removeEventListener("scroll",this.updateScrollEvent);
			for(const e of this.parents) {
				e.removeEventListener("scroll",this.updateScrollEvent);
			}
			window.document.documentElement.removeEventListener("scroll",this.updateScrollEvent,true);
			window.removeEventListener("resize",this.updateResizeEvent);
			this.style.display = "none";
			this.layer.remove();
			if(this.hideNotChildLayers) {
				const childLayers = this.getDeepChildLayers();
				let valid = true;
				for(const e of (new Array(...window.document.body.childNodes)).reverse()) {
					if(e.nodeType === Node.ELEMENT_NODE && e.tagName.toLowerCase() === this.getLayerComponentName() && !childLayers.includes(e) && e.holder.getDisplay() && e.holder.position === "absolute" && e.holder.height === "viewport" && e.holder.width === "viewport") {
						valid = false;
						e.style.display = "";
						const eChildLayers = e.holder.getDeepChildLayers();
						let v = e;
						while(v.nextSibling !== null) {
							v = v.nextSibling;
							if(v.nodeType === Node.ELEMENT_NODE && v.tagName.toLowerCase() === this.getLayerComponentName() && !eChildLayers.includes(v) && v.holder.getDisplay()) {
								v.style.display = "";
							}
						}
						break;
					}
				}
				if(valid) {
					for(const e of window.document.body.childNodes) {
						if(e.nodeType === Node.ELEMENT_NODE) {
							e.style.display = "";
						}
					}
				}
			}
		}
	}
	
	/**
	 * @private
	 */
	addEventListenerParent() {
		for(const e of this.beginDisplayParent) {
			const callback = (event) => {
				this.setDisplay(true);
			};
			this.beginDisplayParentEvents.set(e,callback);
			this.parentNode.addEventListener(e,callback);
		}
		for(const e of this.endDisplayParent) {
			const callback = (event) => {
				this.setDisplay(false);
			};
			this.endDisplayParentEvents.set(e,callback);
			this.parentNode.addEventListener(e,callback);
		}
	}
	
	/**
	 * @private
	 */
	removeEventListenerParent() {
		for(const [k,v] of this.beginDisplayParentEvents) {
			this.parentNode.removeEventListener(k,v);
		}
		this.beginDisplayParentEvents.clear();
		for(const [k,v] of this.endDisplayParentEvents) {
			this.parentNode.removeEventListener(k,v);
		}
		this.endDisplayParentEvents.clear();
	}
	
	/**
	 * @private
	 */
	addEventListenerLayer() {
		for(const e of this.beginDisplayLayer) {
			const callback = (event) => {
				this.setDisplay(true);
			};
			this.beginDisplayLayerEvents.set(e,callback);
			this.layer.addEventListener(e,callback);
		}
		for(const e of this.endDisplayLayer) {
			const callback = (event) => {
				this.setDisplay(false);
			};
			this.endDisplayLayerEvents.set(e,callback);
			this.layer.addEventListener(e,callback);
		}
	}
	
	/**
	 * @private
	 */
	removeEventListenerLayer() {
		for(const [k,v] of this.beginDisplayLayerEvents) {
			this.layer.removeEventListener(k,v);
		}
		this.beginDisplayLayerEvents.clear();
		for(const [k,v] of this.endDisplayLayerEvents) {
			this.layer.removeEventListener(k,v);
		}
		this.endDisplayLayerEvents.clear();
	}
	
	/**
	 * @private
	 */
	getParents() {
		let current = this;
		const list = [];
		while(current !== null) {
			list.push(current);
			current = this.getParent(current);
		}
		return list;
	}
	
	/**
	 * @private
	 */
	getParent(current) {
		return current instanceof ShadowRoot ? current.host : current.parentNode;
	}
	
	/**
	 * @private
	 */
	getParentLayers() {
		let current = this;
		let layer = this.getParentLayer(current);
		const list = [];
		while(layer !== null) {
			list.push(layer);
			current = layer.holder;
			layer = this.getParentLayer(current);
		}
		return list;
	}
	
	/**
	 * @private
	 */
	getParentLayer(begin = null) {
		let current = begin !== null ? begin : this;
		while(current !== null) {
			if(current.nodeType === Node.ELEMENT_NODE && current.tagName.toLowerCase() === this.getLayerComponentName()) {
				return current;
			}
			current = this.getParent(current);
		}
		return null;
	}
	
	/**
	 * @private
	 */
	getDeepChildLayers() {
		const stack = [
			this.layer
		];
		const list = [];
		while(stack.length !== 0) {
			let v = stack.pop();
			if(v.shadowRoot !== null) {
				v = v.shadowRoot;
			}
			for(const e of v.children) {
				if(e.nodeType === Node.ELEMENT_NODE && e.tagName.toLowerCase() === this.getComponentName()) {
					list.push(e.layer);
				} else {
					stack.push(e);
				}
			}
		}
		return list;
	}
	
	/**
	 * @private
	 */
	getChildLayers() {
		const stack = [
			this.layer
		];
		const list = [];
		while(stack.length !== 0) {
			let v = stack.pop();
			if(v.shadowRoot !== null) {
				v = v.shadowRoot;
			}
			for(const e of v.children) {
				if(e.nodeType === Node.ELEMENT_NODE && e.tagName.toLowerCase() === this.getComponentName()) {
					list.push(e.layer);
				} else {
					stack.push(e);
				}
			}
		}
		return list;
	}
	
	/**
	 * @private
	 */
	init(constructor = false) {
		if(constructor) {
			this.identifier = this.constructor.id;
			++this.constructor.id;
			this.layer = window.document.createElement(this.getLayerComponentName());
			this.layer.holder = this;
			this.parents = [];
			this.hideNotChildLayersElements = [];
			this.beginDisplayLayer = [];
			this.beginDisplayParentEvents = new Map();
			this.endDisplayLayer = [];
			this.endDisplayParentEvents = new Map();
			this.beginDisplayParent = [];
			this.beginDisplayLayerEvents = new Map();
			this.endDisplayParent = [];
			this.endDisplayLayerEvents = new Map();
			this.respondToVisibility = Utilities.respondToVisibility(() => {
				this.update();
			});
			this.updateScrollEvent = (event) => {
				this.update();
			};
			this.updateResizeEvent = (event) => {
				this.update();
			};
		}
    	this.removeEventListenerParent();
    	this.removeEventListenerLayer();
		this.beginDisplayLayer.length = 0;
		this.endDisplayLayer.length = 0;
		this.beginDisplayParent.length = 0;
		this.endDisplayParent.length = 0;
		this.hideNotChildLayers = false;
		this.position = "absolute";
		this.height = "absolute";
		this.width = "absolute";
		this.css = "";
		this.setDisplay(false);
	}
	
	/**
	 * @private
	 * @abstract
	 */
	getComponentName() {}
	
	/**
	 * @private
	 * @abstract
	 */
	getLayerComponentName() {}
}

/**
 * @abstract
 * @friend LayerHolder
 */
export class Layer extends HTMLElement {
	/**
	 * @private
	 */
	static id = 0;
	
	/**
	 * @private
	 */
	static properties = new Set(["css"]);
	
	/**
	 * @private
	 */
	constructor() {
		super();
		this.init();
	}
    
	/**
	 * @private
	 */
    connectedCallback() {
		this.style.position = "fixed";
		this.style.overflow = "auto";
	}
    
    /**
     * @private
     */
    async buildProcedure(build) {
    	Utilities.resetElement(this);
		for(const k of build.keys()) {
			if(!this.constructor.properties.has(k)) {
				throw new Error("");
			}
		}
		let css = "";
		for(const [k,v] of build) {
			switch(k) {
				case "css": {
					if(typeof(v) !== "string") {
						throw new Error("");
					}
					css = v;
					break;
				}
			}
		}
		{
			const style = window.document.createElement("style");
			style.type = "text/css";
			style.innerHTML = this.cssToComponent(`
				${await Utilities.getStyle("base",this.getCssBaseUrl(),"base")}
			`,this.identifier.toString())+css;
			this.appendChild(style);
		}
    	this.appendChild(this.wrapper);
    }
	
	/**
	 * @private
	 */
	init() {
		this.identifier = this.constructor.id;
		++this.constructor.id;
		this.wrapper = window.document.createElement("div");
		this.wrapper.classList.add(this.buildIdClass("wrapper"));
	}
	
	/**
	 * @private
	 */
	buildIdClass(name) {
		return Utilities.buildIdClass(this.getComponentName(),name,this.identifier.toString());
	}
	
	/**
	 * @private
	 */
	cssToComponent(cssString,id) {
		return Utilities.cssToComponent(this.getComponentName(),cssString,id);
	}
	
	/**
	 * @private
	 * @abstract
	 */
	getComponentName() {}
	
	/**
	 * @private
	 * @abstract
	 */
	getLayerComponentName() {}
	
	/**
	 * @private
	 * @abstract
	 */
	getCssBaseUrl() {}
}

/**
 * @private
 */
class Utilities {
	/**
	 * 
	 */
	static resetElement(element) {
		while(element.lastChild !== null) {
			element.lastChild.remove();
		}
	}
	
	/**
	 * 
	 */
	static reviver(key,value) {
		if(typeof(value) === "object" && !(value instanceof Array) && value !== null) {
			return new Map(Object.entries(value));
		}
		return value;
	}
	
	/**
	 * 
	 */
	static cssToCamelCase(value) {
		return value.replace(new RegExp("-([^])","g"),(match,p1,offset,string) => {
			return p1.toUpperCase();
		});
	}
	
	/**
	 * 
	 */
	static respondToVisibility(callback) {
		const observer = new IntersectionObserver((entries,observer) => {
			for(let i = 0; i < entries.length; ++i) {
				callback();
			}
		});
		return observer;
	}
	
	/**
	 * 
	 */
	static async getStyle(resource,baseUrl,folder = "css") {
		try {
			const headers = new Headers();
			const init = {
				"method": "GET",
				"headers": headers,
				"credentials": "same-origin"
			};
			const response = await window.fetch(baseUrl+folder+"/"+resource+".css",init);
			if(response.status === 200) {
				return await response.text();
			} else {
				throw new Error("");
			}
		} catch(e) {
			throw new Error("");
		}
	}
	
	/**
	 * 
	 */
	static buildIdClass(componentName,name,identifier) {
		return componentName+"---"+name+"---"+identifier;
	}
	
	/**
	 * 
	 */
	static cssToComponent(componentName,cssString,id) {
		const cssParsed = Css.parse(cssString,{
			"silent": false
		});
		this.cssToComponentSubroutine(componentName,cssParsed.stylesheet.rules,id);
		return Css.stringify(cssParsed);
	}
	
	/**
	 * @private
	 */
	static cssToComponentSubroutine(componentName,rules,id) {
		for(const rule of rules) {
			switch(rule.type) {
				case "media": {
					this.cssToComponentSubroutine(componentName,rule.rules,id);
					break;
				}
				case "rule": {
					const selectors = rule.selectors;
					for(const [index,selector] of selectors.entries()) {
						const preprocess = [];
						const selectorUnicode = Array.from(selector);
						for(let i = 0; i < selectorUnicode.length; ++i) {
							const e = selectorUnicode[i];
							const charCode = e.charCodeAt(0);
							switch(charCode) {
								case 12: {
									preprocess.push("\n");
									break;
								}
								case 13: {
									if(i+1 !== selectorUnicode.length && selectorUnicode[i+1].charCodeAt(0) === 10) {
										++i;
									}
									preprocess.push("\n");
									break;
								}
								default: {
									preprocess.push(e);
									break;
								}
							}
						}
						const res = [];
						let onIdClass = false;
						for(const [i,e] of preprocess.entries()) {
							const charCode = e.charCodeAt(0);
							if(!onIdClass) {
								if([35,46].includes(charCode)) {
									if(i+1 !== preprocess.length) {
										const charCode1 = preprocess[i+1].charCodeAt(0);
										if(charCode1 === 45) {
											if(i+2 !== preprocess.length) {
												const charCode2 = preprocess[i+2].charCodeAt(0);
												if(charCode2 === 45 || (charCode2 >= 65 && charCode2 <= 90) || charCode2 === 95 || (charCode2 >= 97 && charCode2 <= 122) || charCode2 >= 128 || (charCode2 === 92 && i+3 !== preprocess.length && preprocess[i+3].charCodeAt(0) !== 10)) {
													onIdClass = true;
												}
											}
										} else if((charCode1 >= 65 && charCode1 <= 90) || charCode1 === 95 || (charCode1 >= 97 && charCode1 <= 122) || charCode1 >= 128) {
											onIdClass = true;
										} else if(charCode1 === 92 && i+2 !== preprocess.length && preprocess[i+2].charCodeAt(0) !== 10) {
											onIdClass = true;
										}
									}
								}
								res.push(e);
								if(onIdClass) {
									res.push(componentName+"---");
								}
							} else {
								if(!(charCode === 45 || (charCode >= 48 && charCode <= 57) || (charCode >= 65  && charCode <= 90) || charCode === 95 || (charCode >= 97 && charCode <= 122) || charCode >= 128 || (charCode === 92 && i+3 !== preprocess.length && preprocess[i+3].charCodeAt(0) !== 10))) {
									res.push("---"+id);
									onIdClass = false;
								}
								res.push(e);
							}
						}
						if(onIdClass) {
							res.push("---"+id);
						}
						selectors[index] = res.join("");
					}
					break;
				}
			}
		}
	}
}

/**
 * 
 */
class LayerHolderInstance extends LayerHolder {
	/**
	 * @private
	 */
	getComponentName() {
		return "web-layer-holder";
	}
	
	/**
	 * @private
	 */
	getLayerComponentName() {
		return "web-layer";
	}
}

/**
 * 
 */
class LayerInstance extends Layer {
	/**
	 * @private
	 */
	getComponentName() {
		return "web-layer";
	}
	
	/**
	 * @private
	 */
	getLayerHolderComponentName() {
		return "web-layer-holder";
	}
	
	/**
	 * @private
	 */
	getCssBaseUrl() {
		return baseUrl;
	}
}

window.customElements.define("web-layer-holder",LayerHolderInstance);
window.customElements.define("web-layer",LayerInstance);