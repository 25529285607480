/**
 * Utilities class.
 * 
 * @module webComponents/build/utilities
 */
import {session,state,rootUri} from "../../routing.js";
import "../layer/layer.js";
import UtilitiesBase from "./utilitiesBase.js";

const libraryUrl = window.location.origin+rootUri+"libraries/client/";

/**
 * 
 */
export default class Utilities extends UtilitiesBase {
	/**
	 * Display an element with math content.
	 * 
	 * @param {HTMLElement} element
	 * @param {string} text
	 * @param {ErrorHandler} [errorHandler=null]
	 * @return .
	 */
	static async displayMath(element,text,errorHandler = null) {
		const tmp = window.document.createElement("div");
		tmp.innerHTML = text.split(new RegExp("href=\"https://sciences-en-ligne\\.net/","g")).join("href=\""+window.location.origin+rootUri);
		this.resetElement(element);
		element.appendChild(tmp);
		element = tmp;
		if(!window.hasOwnProperty("MathJax")) {
			await this.setScript("https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML",new Map([
				["variable","MathJax"],
				["withSrc",true]
			]),errorHandler);
		}
    	const div = window.document.createElement("div");
    	div.style.display = "none";
    	let res = null;
    	(() => {
    		const callback = window.MathJax.Hub.signal.Interest(async (message) => {
    			if(message[0] === "End Process" && message[1] === div) {
    				window.MathJax.Hub.signal.NoInterest(callback);
    				const build = div.cloneNode(true);
    				div.remove();
    				build.style.display = "block";
    			    if(element.parentNode !== null) {
    			    	const tmp = element.parentNode;
    			    	this.resetElement(tmp);
    			    	tmp.appendChild(build);
    			    }
    			    await this.setStyle(build,libraryUrl+"mathjax/mathjax.css",this,errorHandler);
    			    res = build;
    			}
	    	},true);
    	})();
    	div.innerHTML = text.split(new RegExp("href=\"https://sciences-en-ligne\\.net/","g")).join("href=\""+window.location.origin+rootUri);
	    if(element.parentNode !== null) {
	    	element.parentNode.appendChild(div);
	    }
	    window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub,div]);
	    while(res === null) {
			await new Promise((resolve,reject) => {
				window.setTimeout(resolve,500);
		    });
	    }
	    return res;
	}
	
	/**
	 * Display an image which might be zoomed
	 * 
	 * @param {HTMLElement} element
	 * @param {string} src
	 * @param {number} [height=-1]
	 * @param {number} [width=-1]
	 * @param {Map()} [zoom=new Map()]
	 * @return .
	 */
	static displayImg(element,src,height = -1,width = -1,zoom = new Map()) {
		if(!zoom.has("zoomable")) {
			zoom.set("zoomable",false);
		}
		if(zoom.get("zoomable")) {
			if(!zoom.has("text")) {
				zoom.set("text","");
			}
			if(!zoom.has("math")) {
				zoom.set("math",false);
			}
		}
		let fileExtension = "";
		const matches = (new RegExp("[\\s\\S]*\\.([^\\.]+)")).exec(src);
		if(matches !== null) {
			fileExtension = matches[1];
		}
		const res = new Map();
		if(fileExtension.toLowerCase() === "swf") {
			if(height === -1 && width === -1) {
				width = "100%";
			} else if(width === -1) {
				width = Math.floor(height*4/3);
			} else if(height === -1) {
				height = Math.floor(width*3/4);
			}
			{
				const object = window.document.createElement("object");
				object.data = src;
				if(height !== -1) {
					object.height = height;
				}
				object.width = width;
				res.set("object",object);
				element.appendChild(object);
				{
					const access = window.document.createElement("param");
					access.name = "allowScriptAccess";
					access.value = "never";
					object.appendChild(access);
				}
				{
					const wmode = window.document.createElement("param");
					wmode.name = "wmode";
					wmode.value = "transparent";
					object.appendChild(wmode);
				}
				if(zoom.get("zoomable")) {
					const param = window.document.createElement("param");
					param.name = "allowFullScreen";
					param.value = "true";
					object.appendChild(param);
				}
			}
		} else {
			const div = window.document.createElement("div");
			res.set("wrapper",div);
			element.appendChild(div);
			if(zoom.get("zoomable")) {
				div.style.cursor = "zoom-in";
				{
					const holder = window.document.createElement("web-layer-holder");
					const buildLayer = new Map([
						["begin-display",new Map([
							["parent",["click"]]
						])],
						["end-display",new Map([
							["layer",["click"]]
						])],
						["hide-not-child-layers",true]
					]);
					if(zoom.has("zoom-css")) {
						buildLayer.set("css",zoom.get("zoom-css"));
					}
					holder.buildProcedure(buildLayer);
					res.set("wrapper-zoom",holder);
					div.appendChild(holder);
					holder.getLayer().style.padding = "20px";
					holder.getLayer().style.height = "100vh";
					holder.getLayer().style.width = "100vw";
					holder.getLayer().style.backgroundColor = "rgba(255,255,255,1)";
					holder.getLayer().style.cursor = "zoom-out";
					res.set("zoom",holder.getLayer());
					{
						const img = window.document.createElement("img");
						if(height !== -1) {
							img.height = height;
						}
						if(width !== -1) {
							img.width = width;
						}
						img.src = src;
						img.style.objectFit = "contain";
						img.style.height = zoom.get("text") !== "" ? "80%" : "100%";
						res.set("zoom-img",div);
						holder.getLayer().appendChild(img);
					}
					if(zoom.get("text") !== "") {
						const textWrapper = window.document.createElement("div");
						textWrapper.style.padding = "10px";
						textWrapper.style.height = "20%";
						textWrapper.style.overflow = "auto";
						textWrapper.style.textAlign = "center";
						textWrapper.style.pointerEvents = "none";
						res.set("zoom-text",div);
						holder.getLayer().appendChild(textWrapper);
						if(!zoom.get("math")) {
							textWrapper.appendChild(window.document.createTextNode(zoom.get("text")));
						} else {
							this.displayMath(textWrapper,zoom.get("text"));
						}
					}
				}
			}
			{
				const img = window.document.createElement("img");
				if(height !== -1) {
					img.height = height;
				}
				if(width !== -1) {
					img.width = width;
				}
				img.src = src;
				res.set("img",img);
				div.appendChild(img);
			}
		}
		return res;
	}
	
	/**
	 * Display text eventually followed by ellipsis
	 * 
	 * @param {HTMLElement} element
	 */
	static displayTextWithEllipsis(element) {
		const text = element.innerHTML;
		this.displayTextWithEllipsisSubroutine(element,text);
		window.addEventListener("resize",(event) => {
			element.innerHTML = text;
			this.displayTextWithEllipsisSubroutine(element,text);
		});
	}
	
	/**
	 * @private
	 * 
	 * @param {HTMLElement} element
	 * @param {string} text
	 */
	static displayTextWithEllipsisSubroutine(element,text) {
		window.requestIdleCallback((deadline) => {
			if(element.clientHeight < element.scrollHeight) {
				const computedStyle = window.getComputedStyle(element);
				if(["visible","hidden"].includes(computedStyle.getPropertyValue("overflow"))) {
					const isBorderBox = computedStyle.getPropertyValue("box-sizing") === "border-box";
					const searchList = ["height","width"];
					for(const e of ["border","padding"]) {
						for(const v of ["top","right","bottom","left"]) {
							searchList.push(e+"-"+v+(e === "border" ? "-width" : ""));
						}
					}
					const computedPx = new Map();
					for(const e of searchList) {
						const v = computedStyle.getPropertyValue(e);
						if(v === "auto") {
							return;
						}
						computedPx.set(e,window.parseFloat(v.substring(0,v.length-2)));
					}
					const minus = [];
					if(isBorderBox) {
						minus.push("border","padding");
					}
					const minusHeight = [];
					const minusWidth = [];
					for(const e of minus) {
						minusHeight.push(e+"-top"+(e === "border" ? "-width" : ""),e+"-bottom"+(e === "border" ? "-width" : ""));
						minusWidth.push(e+"-right"+(e === "border" ? "-width" : ""),e+"-left"+(e === "border" ? "-width" : ""));
					}
					let height = computedPx.get("height");
					for(const e of minusHeight) {
						height -= computedPx.get(e);
					}
					let width = computedPx.get("width");
					for(const e of minusWidth) {
						width -= computedPx.get(e);
					}
					if(height > 0.0) {
						const build = window.document.createElement("div");
				    	for(let i = 0; i < computedStyle.length; ++i) {
				    		const property = computedStyle.item(i);
				    		if(property.length > 0 && property[0] !== "-") {
				    			build.style[property] = computedStyle.getPropertyValue(property);
				    		}
				    	}
						build.style.display = "block";
						build.style.position = "absolute";
						for(const e of ["height","width"]) {
							build.style["min-"+e] = "0px";
						}
						for(const e of ["margin","border","padding"]) {
							for(const v of ["top","right","bottom","left"]) {
								build.style[e+"-"+v+(e === "border" ? "-width" : "")] = "0px";
							}
						}
						build.style.height = "0px";
						build.style.width = "0px";
						for(const e of ["height","width"]) {
							build.style["max-"+e] = build.style[e];
						}
						window.document.body.appendChild(build);
						{
							build.appendChild(window.document.createTextNode("..."));
						}
						{
							let characters = null;
							let a = -1;
							window.requestIdleCallback((deadline) => {
								build.style.height = height.toString()+"px";
								build.style.width = width.toString()+"px";
								for(const e of ["height","width"]) {
									build.style["max-"+e] = build.style[e];
								}
								if(build.clientHeight === build.scrollHeight) {
									characters = Array.from(text);
									a = 0;
									let c = 0;
									let b = characters.length;
									while(a !== b) {
										c = Math.ceil((a+b)/2);
										while(build.lastChild !== null) {
											build.lastChild.remove();
										}
										build.appendChild(window.document.createTextNode(characters.slice(0,c).join("")+"..."));
										if(build.clientHeight < build.scrollHeight) {
											b = c-1;
										} else {
											a = c;
										}
									}
								}
								while(element.lastChild !== null) {
									element.lastChild.remove();
								}
								if(a !== -1) {
									element.appendChild(window.document.createTextNode(characters.slice(0,a).join("")+"..."));
								}
								build.remove();
							});
						}
					}
				}
			}
		});
	}
	
	/**
	 * Create XLSX document.
	 * 
	 * @param {Map} [build=new Map()]
	 * @param {ErrorHandler} [errorHandler=null]
	 * @return .
	 */
	static async createXlsx(build = new Map(),errorHandler = null) {
		if(!window.hasOwnProperty("XLSX")) {
			await this.setScript(libraryUrl+"sheetjs/xlsx.mini.js",new Map([
				["variable","XLSX"]
			]),errorHandler);
		}
		const workBook = window.XLSX.utils.book_new();
		workBook.Props = {
			"Title": build.get("title"),
			"Subject": build.get("subject"),
			"Author": build.get("author"),
			"CreatedDate": build.get("date")
		};
		workBook.SheetNames.push(build.get("sheet_name"));
		const workSheet = window.XLSX.utils.aoa_to_sheet(build.get("content"));
		workBook.Sheets[build.get("sheet_name")] = workSheet;
		return window.XLSX.write(workBook,{
			"bookType": "xlsx",
			"type": "array"
		});
	}
	
	/**
	 * Update the history.
	 * 
	 * @param {string} uri
	 * @param {boolean} [historyPush=false]
	 */
	static buildHistory(uri,historyPush = false) {
		const url = window.location.origin+rootUri+uri;
		if(historyPush) {
			if(state["history"][state["position"]] !== uri) {
				while(state["history"].length-1 > state["position"]) {
					state["history"].pop();
				}
				state["history"].push(uri);
				++state["position"];
				window.history.pushState(state,"",url);
			}
		} else {
			state["history"][state["position"]] = uri;
			window.history.replaceState(state,"",url);
		}
	}
	
	/**
	 * Send JSON data in POST query.
	 * 
	 * @param {string} resource
	 * @param {Map} [data=new Map()]
	 * @param {boolean} [includeCsrf=true]
	 * @return data
	 */
	static async sendPostJsonQuery(resource,data = new Map(),includeCsrf = true) {
		try {
			const headers = new Headers();
			let fd = null;
			if(!(data instanceof FormData)) {
				fd = new FormData();
				for(const [k,v] of data) {
					if(typeof(v) === "boolean") {
						fd.set(k,v ? "true" : "false");
					} else if(typeof(v) === "number") {
						fd.set(k,v.toString());
					} else if(typeof(v) === "string") {
						fd.set(k,v);
					} else if(v instanceof File) {
						fd.set(k,v,v.name);
					}
				}
			} else {
				fd = data;
			}
			const init = {
				"method": "POST",
				"headers": headers,
				"body": fd,
				"credentials": "same-origin"
			};
			const url = new URL(resource);
			const search = new URLSearchParams(url.search.slice(1));
			if(includeCsrf) {
				search.append("csrf",session.get("csrf"))
				url.search = "?"+search.toString();
			}
			const response = await window.fetch(url.href,init);
			if(response.status === 200) {
				return this.stringToJson(await response.text());
			}
		} catch(e) {}
		return new Map([
			["response",false],
			["value",new Map([
				["code","CLIENT_ERROR_REQUEST"]
			])]
		]);
	}
}